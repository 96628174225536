import { useEffect } from 'react'
// import { isMobile } from 'react-device-detect'
import Lenis from '@studio-freight/lenis'

const ScrollPage = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    const lenis = new Lenis()
    // let ease = isMobile ? 0.4 : 0.1

    body.addEventListener('finishedPreloader', function () {
      body.dispatchEvent(new Event('pageChanged'))
    })

    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }

    requestAnimationFrame(raf)
    window.lenis = lenis
  }, [])

  return false
}

export default ScrollPage
