import React, { useEffect } from 'react'
import './PreloaderStyle.scss'

import { ReactComponent as Logo1 } from 'assets/vectors/general/header-logo-1.svg'
import { ReactComponent as Logo2 } from 'assets/vectors/general/header-logo-2.svg'
import { ReactComponent as Logo3 } from 'assets/vectors/general/header-logo-3.svg'
import { ReactComponent as Logo4 } from 'assets/vectors/general/header-logo-4.svg'
import { ReactComponent as Logo5 } from 'assets/vectors/general/header-logo-5.svg'

const Preloader = () => {
  useEffect(() => {
    const isProduction = window.location.href.indexOf('dex01') !== -1
    const preloader = document.querySelector('#main-preloader')
    const body = document.querySelector('body')

    window.preloader_active = true

    if (isProduction) {
      setTimeout(() => preloader.classList.add('active'), 100)

      setTimeout(function () {
        body.dispatchEvent(new Event('finishedPreloader'))
        body.classList.remove('not-scrollable')

        preloader.classList.add('remove')
        window.preloader_active = false
      }, 1200)
    } else {
      setTimeout(function () {
        body.dispatchEvent(new Event('finishedPreloader'))
        body.classList.remove('not-scrollable')

        preloader.classList.add('remove')
        window.preloader_active = false
      }, 400)
    }
  }, [])

  return (
    <div id="main-preloader">
      <div className="logo">
        <Logo1 />
        <Logo2 />
        <Logo3 />
        <Logo4 />
        <Logo5 />

        <div className="logo-bar"></div>
      </div>
    </div>
  )
}

export default Preloader
