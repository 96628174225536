import React, { useRef, useEffect, useState } from 'react'
import API from 'api'
import { Link } from 'react-router-dom'
import './HeaderStyle.scss'

import { ReactComponent as Menu } from 'assets/vectors/general/header-menu.svg'
import { ReactComponent as Close } from 'assets/vectors/general/header-close.svg'
import { ReactComponent as LogoFull } from 'assets/vectors/general/header-logo.svg'
// import { ReactComponent as Logo1 } from 'assets/vectors/general/header-logo-1.svg'
// import { ReactComponent as Logo2 } from 'assets/vectors/general/header-logo-2.svg'
// import { ReactComponent as Logo3 } from 'assets/vectors/general/header-logo-3.svg'
// import { ReactComponent as Logo4 } from 'assets/vectors/general/header-logo-4.svg'
// import { ReactComponent as Logo5 } from 'assets/vectors/general/header-logo-5.svg'
import { ReactComponent as SocialInstagram } from 'assets/vectors/general/social-instagram.svg'
import { ReactComponent as SocialLinkedin } from 'assets/vectors/general/social-linkedin.svg'
import { ReactComponent as SocialMedium } from 'assets/vectors/general/social-medium.svg'
import { ReactComponent as SocialTiktok } from 'assets/vectors/general/social-tiktok.svg'

const Header = () => {
  const [menuMobile, setMenuMobile] = useState()
  const [hide, setHide] = useState()
  const [sticky, setSticky] = useState()
  const [shop, setShop] = useState()
  const header = useRef(null)

  useEffect(() => {
    API.get('', {
      params: {
        action: 'load_general',
      },
    }).then((result) => {
      const info = result.data

      if (info.header.shop) {
        setShop(true)
      }
    })
  }, [])

  useEffect(() => {
    let lastScroll = 0

    window.addEventListener('scroll', () => {
      const currentScroll = window.pageYOffset

      if (currentScroll > 1) {
        setSticky(true)
      } else {
        setSticky(false)
      }

      if (currentScroll > lastScroll) {
        setHide(true)
      } else {
        setHide(false)
      }

      lastScroll = currentScroll
    })
  }, [])

  useEffect(() => {
    if (menuMobile === undefined) return

    const body = document.querySelector('body')

    if (menuMobile) {
      body.classList.add('not-scrollable')
    } else {
      body.classList.remove('not-scrollable')
    }
  }, [menuMobile])

  return (
    <header
      id="main-header"
      className={`${hide ? 'hide' : ''} ${sticky ? 'sticky' : ''}`}
      ref={header}
    >
      <div className="container">
        <div className="flex-wrapper">
          <div className="logo">
            <Link to="/" aria-label="Ir para página principal">
              <LogoFull />
            </Link>
          </div>

          {/*<div className="logo-old">
            <Link to="/" aria-label="Ir para página principal">
              <Logo1 />
              <Logo2 />
              <Logo3 />
              <Logo4 />
              <Logo5 />
            </Link>
          </div>*/}

          <div className="menu-toggler">
            <button
              type="button"
              aria-label="Abrir menu"
              onClick={() => setMenuMobile(true)}
            >
              <Menu />
            </button>
          </div>

          <div className="navigation">
            <ul>
              <li>
                <Link
                  to="/sobre"
                  aria-label="Sobre"
                  onClick={() => setMenuMobile(false)}
                >
                  Sobre
                </Link>
              </li>

              <li>
                <Link to="/projetos" aria-label="Projetos">
                  Projetos
                </Link>
              </li>

              <li>
                <Link to="/vagas" aria-label="Vagas">
                  Vagas
                </Link>
              </li>

              {shop && (
                <li>
                  <Link to="/loja" aria-label="Shop">
                    Shop
                  </Link>
                </li>
              )}

              <li>
                <Link to="/contato" aria-label="Contato">
                  Contato
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={`menu-mobile ${menuMobile ? 'active' : ''}`}>
        <div className="close">
          <button
            type="button"
            aria-label="Fechar"
            onClick={() => setMenuMobile(false)}
          >
            <Close />
          </button>
        </div>

        <div className="container">
          <div className="navigation">
            <ul>
              <li>
                <Link
                  to="/"
                  aria-label="Início"
                  onClick={() => setMenuMobile(false)}
                >
                  Início
                </Link>
              </li>

              <li>
                <Link
                  to="/sobre"
                  aria-label="Sobre"
                  onClick={() => setMenuMobile(false)}
                >
                  Sobre
                </Link>
              </li>

              <li>
                <Link
                  to="/projetos"
                  aria-label="Projetos"
                  onClick={() => setMenuMobile(false)}
                >
                  Projetos
                </Link>
              </li>

              <li>
                <Link
                  to="/vagas"
                  aria-label="Vagas"
                  onClick={() => setMenuMobile(false)}
                >
                  Vagas
                </Link>
              </li>

              {shop && (
                <li>
                  <Link
                    to="/loja"
                    aria-label="Shop"
                    onClick={() => setMenuMobile(false)}
                  >
                    Shop
                  </Link>
                </li>
              )}

              <li>
                <Link
                  to="/contato"
                  aria-label="Contato"
                  onClick={() => setMenuMobile(false)}
                >
                  Contato
                </Link>
              </li>
            </ul>
          </div>

          <div className="social">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/dex01_/"
                  aria-label="Acessar rede social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialInstagram />
                </a>
              </li>

              <li>
                <a
                  href="https://www.linkedin.com/company/dex01/"
                  aria-label="Acessar rede social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialLinkedin />
                </a>
              </li>

              <li>
                <a
                  href="https://medium.com/dex01"
                  aria-label="Acessar rede social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialMedium />
                </a>
              </li>

              <li>
                <a
                  href="https://www.tiktok.com/@dex01_"
                  aria-label="Acessar rede social"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <SocialTiktok />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
