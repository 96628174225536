import React, { useEffect, createRef, Suspense, lazy } from 'react'
import { Helmet } from 'react-helmet'
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useOutlet,
} from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import Appear from 'utils/appear'
import LazyLoad from 'utils/lazy-load'
import ScrollPage from 'utils/scroll-page'

import Header from 'components/general/Header'
import Preloader from 'components/general/Preloader'

const Home = lazy(() => import('container/home'))
const About = lazy(() => import('container/about'))
const Contact = lazy(() => import('container/contact'))
const Projects = lazy(() => import('container/projects'))
const Project = lazy(() => import('container/project'))
const Vacancies = lazy(() => import('container/vacancies'))
const Shop = lazy(() => import('container/shop'))
const Editorial = lazy(() => import('container/editorial'))
const PrivacyPolicy = lazy(() => import('container/privacy'))
const Error404 = lazy(() => import('container/error'))

const App = () => {
  useEffect(() => {
    const body = document.querySelector('body')

    body.addEventListener('loadedContent', function () {
      if (!window.preloader_active) {
        const pages = document.querySelectorAll('.root-page')
        let page = pages[0]

        if (pages.length >= 2) {
          for (const item of pages) {
            const classes = item.getAttribute('class')

            if (classes.includes('enter')) page = item
          }
        }

        page.classList.add('loaded-content')

        if (page.classList.contains('transition-enter-done')) {
          body.dispatchEvent(new Event('resize'))
          setTimeout(() => body.dispatchEvent(new Event('pageChanged')), 500)
        }
      }
    })
  }, [])

  const scrollToTop = () => {
    setTimeout(function () {
      if (window.lenis) {
        window.lenis.scrollTo(0)
      }
      // window.scrollTo({
      //   top: 0,
      //   behavior: 'smooth',
      // })
    }, 400)
  }

  const updateScroll = (e) => {
    const body = document.querySelector('body')
    const page = document.querySelector('.root-page.transition-enter-done')

    if (page.classList.contains('loaded-content')) {
      body.dispatchEvent(new Event('resize'))
      setTimeout(() => body.dispatchEvent(new Event('pageChanged')), 500)
    }
  }

  const routes = [
    {
      path: '/',
      name: 'home',
      title: 'Início',
      element: <Home />,
      nodeRef: createRef(),
    },
    {
      path: '/sobre',
      name: 'about',
      title: 'Sobre',
      element: <About />,
      nodeRef: createRef(),
    },
    {
      path: '/sobre/',
      name: 'about',
      title: 'Sobre',
      element: <About />,
      nodeRef: createRef(),
    },
    {
      path: '/contato',
      name: 'contact',
      title: 'Contato',
      element: <Contact />,
      nodeRef: createRef(),
    },
    {
      path: '/contato/',
      name: 'contact',
      title: 'Contato',
      element: <Contact />,
      nodeRef: createRef(),
    },
    {
      path: '/projeto/:projeto',
      name: 'project',
      title: 'Projeto',
      element: <Project />,
      nodeRef: createRef(),
    },
    {
      path: '/projeto/:projeto/',
      name: 'project',
      title: 'Projeto',
      element: <Project />,
      nodeRef: createRef(),
    },
    {
      path: '/projetos',
      name: 'projects',
      title: 'Projetos',
      element: <Projects />,
      nodeRef: createRef(),
    },
    {
      path: '/projetos/',
      name: 'projects',
      title: 'Projetos',
      element: <Projects />,
      nodeRef: createRef(),
    },
    {
      path: '/vagas',
      name: 'vacancies',
      title: 'Vagas',
      element: <Vacancies />,
      nodeRef: createRef(),
    },
    {
      path: '/vagas/',
      name: 'vacancies',
      title: 'Vagas',
      element: <Vacancies />,
      nodeRef: createRef(),
    },
    {
      path: '/loja',
      name: 'shop',
      title: 'Loja',
      element: <Shop />,
      nodeRef: createRef(),
    },
    {
      path: '/loja/',
      name: 'shop',
      title: 'Loja',
      element: <Shop />,
      nodeRef: createRef(),
    },
    {
      path: '/editorial',
      name: 'editorial',
      title: 'Editorial',
      element: <Editorial />,
      nodeRef: createRef(),
    },
    {
      path: '/editorial/',
      name: 'editorial',
      title: 'Editorial',
      element: <Editorial />,
      nodeRef: createRef(),
    },
    {
      path: '/politica-de-privacidade',
      name: 'privacy-policy',
      title: 'Política de privacidade',
      element: <PrivacyPolicy />,
      nodeRef: createRef(),
    },
    {
      path: '/politica-de-privacidade/',
      name: 'privacy-policy',
      title: 'Política de privacidade',
      element: <PrivacyPolicy />,
      nodeRef: createRef(),
    },
    {
      path: '*',
      name: 'error-page',
      title: 'Página não encontrada',
      element: <Error404 />,
      nodeRef: createRef(),
    },
  ]

  const RenderRoutes = () => {
    const location = useLocation()
    const currentOutlet = useOutlet()
    const { name, nodeRef, title } =
      routes.find((route) => route.path === location.pathname) ?? {}

    return (
      <>
        <Header />
        <Preloader />

        <TransitionGroup className="page-transition">
          <CSSTransition
            key={location.pathname}
            nodeRef={nodeRef}
            timeout={500}
            classNames="transition"
            onEnter={() => scrollToTop()}
            onEntered={(e) => updateScroll(e)}
          >
            {(state) => (
              <div
                ref={nodeRef}
                id={name ? `root-${name}` : 'root'}
                className="root-page"
              >
                <Helmet>
                  {title ? (
                    <title>{title} | DEx01</title>
                  ) : (
                    <title>DEx01</title>
                  )}
                </Helmet>

                <Suspense fallback={<div className="loading-page"></div>}>
                  {currentOutlet}
                </Suspense>
              </div>
            )}
          </CSSTransition>
        </TransitionGroup>
      </>
    )
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <RenderRoutes />,
      children: routes.map((route) => ({
        index: route.path === '/',
        path: route.path === '/' ? undefined : route.path,
        element: route.element,
      })),
    },
  ])

  return (
    <>
      <Appear />
      <LazyLoad />
      <ScrollPage />

      <RouterProvider router={router} />
    </>
  )
}

export default App
